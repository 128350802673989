import Events from "pages/Events"
import Home from "pages/Home"
import Team from "pages/Team"
import YouLeadSummit23 from "pages/YouLeadSummit23"
import PhotoGallery from "pages/PhotoGallery"
import VideoGallery from "pages/VideoGallery"
import Blogs from "pages/Blog"
import EventReports from "pages/EventReports"
import ResearchPublications from "pages/ResearchPublications"
import Partners from "pages/Partners"
import YouLeadSummit22 from "pages/YouLeadSummit22"
import Icoyaca from "pages/Icoyaca"
import ComingSoon from "pages/ComingSoon"
import Eaypn from "pages/Eaypn"
import Afybc from "pages/Afybc"
import Projects from "pages/Projects"
import ProjectsSingle from "pages/Projects/projectsSingle"

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/you-lead-summit", component: YouLeadSummit23 },
  { path: "/you-lead-summit-22", component: YouLeadSummit22 },
  { path: "/team", component: Team },
  { path: "/interactive-events", component: Events },
  { path: "/interactive-photos", component: PhotoGallery },
  { path: "/interactive-videos", component: VideoGallery },
  { path: "/interactive-blogs", component: Blogs },
  { path: "/interactive-reports", component: EventReports },
  { path: "/interactive-publications", component: ResearchPublications },
  { path: "/projects", component: Projects },
  { path: "/project-single", component: ProjectsSingle },
  { path: "/partners", component: Partners },
  { path: "/entities-icoyaca", component: Icoyaca },
  { path: "/entities-eaypn", component: Eaypn },
  { path: "/entities-afybc", component: Afybc },
  { path: "/coming-soon", component: ComingSoon },
]
export { publicRoutes }
