import React, { useEffect } from "react"; 

const TwitterContainer = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        document.getElementsByClassName("twitter-embed")[0].appendChild(script);
      }, []);
    return (
        <section className="twitterContainer">
        <div className="twitter-embed">
          <a
            className="twitter-timeline"
            data-theme="dark"
            data-height="500"
            data-tweet-limit="5"
            data-chrome="noheader nofooter noborders"
            href="https://twitter.com/OneYoungAfrica?ref_src=twsrc%5Etfw"
          >
            Tweets by OneYoungAfrica
          </a>
        </div>
      </section>
    );
};

export default TwitterContainer;